import * as React from "react"

import Layout from "../components/common/Layout"
import SEO from "../components/seo"
import { ContentWrapper } from "../components/ContentWrapper"
import { TickIcon } from "../components/icons/Man"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ContentWrapper>
      <TickIcon size="300" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that does not exist... the sadness.</p>
    </ContentWrapper>
  </Layout>
)

export default NotFoundPage
